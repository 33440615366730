import React, { useState } from 'react'
import GAListTable from './components/gaListTable'
import SubjectManagement from './components/ga-configure/subjectListTable'
const GoogleAnalyticManagement = () => {
  const [isRefetchList, setIsRefetchList] = useState(true)
  return (
    <div className="px-20 py-10 md:px-10">
      <GAListTable isRefetchList={isRefetchList} setIsRefetchList={setIsRefetchList} />
      <hr className="my-10 border-t-neutral-6" />
      <SubjectManagement />
    </div>
  )
}

export default GoogleAnalyticManagement
