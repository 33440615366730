import React from 'react'
import classNames from 'classnames'
import CheckBoxBase from '../../../../components/partials/toggleBase'
import IconTrashInActive from '../../../../assets/images/icon-trash-inactive.svg'
import IconTrashActive from '../../../../assets/images/icon-trash-active.svg'
import IconStatus from '../../../../assets/images/icon-status.svg'
import IconPen from '../../../../assets/images/icon-pen-bold.svg'

interface PropsAction {
  handleToggle: () => void
  status?: boolean | null
  handleDeleteConfiguration: () => void
  handleChangeConfigurationValue: () => void
}

const RowActionConfigurationList: React.FunctionComponent<PropsAction> = ({
  status,
  handleToggle = () => null,
  handleDeleteConfiguration = () => null,
  handleChangeConfigurationValue = () => null,
}) => {
  const eDivClasses = classNames(
    `flex items-center p-2 hover:bg-neutral-7 hover:rounded-lg pointer-events-auto`,
  )
  const eSpanClasses = classNames(
    `flex ml-3 min-w-[108px] font-montserrat font-medium text-base text-neutral-3`,
  )

  const onHandleToggle = () => {
    // setValue(!value)
    handleToggle()
  }

  return (
    <div className="h-auto w-60 border border-neutral-7 bg-neutral-8 shadow-2xl rounded-xl relative p-3">
      <div className={`${eDivClasses} justify-between`}>
        <div className="flex">
          <img src={IconStatus} alt="Icon-Status" />
          <span className={`${eSpanClasses}`}>Status</span>
        </div>
        <div>
          <CheckBoxBase value={status as boolean} handleToggle={onHandleToggle} />
        </div>
      </div>
      <hr className="border-t-neutral-7 my-1" />
      <div className={`${eDivClasses} justify-between`}>
        <div className="flex">
          <img src={IconPen} className="min-w-[24px]" alt="Icon-Pen" />
          <span className={`${eSpanClasses}`} onClick={handleChangeConfigurationValue}>
            Edit
          </span>
        </div>
      </div>
      {!status && <hr className="border-t-neutral-7 my-1" />}
      <div className={`${eDivClasses} ${status ? 'hidden' : 'block'}`}>
        <img src={status ? `${IconTrashInActive}` : `${IconTrashActive}`} alt="Icon-Trash" />
        <span
          onClick={handleDeleteConfiguration}
          className={`flex ml-3 min-w-[108px] font-montserrat text-body1 ${
            status ? 'text-neutral-500' : 'text-indicator-red'
          }`}
        >
          Delete
        </span>
      </div>
    </div>
  )
}

export default RowActionConfigurationList
