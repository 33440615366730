import React from 'react'
import BaseDialog from '../../../components/dialog'
import ButtonBase from '../../../components/partials/button'
import { defaultCustomStyles } from '../../../constants/customStyles'
import iconTimes from '../../../assets/images/icon-times.svg'

type Props = {
  modalIsOpen?: boolean
  statusValue?: boolean | null
  handleCloseDialog?: (val: boolean) => void
  handleChangeStatusConfiguration?: () => void
}

const ChangeStatusDialog: React.FunctionComponent<Props> = ({
  modalIsOpen,
  statusValue,
  handleCloseDialog = () => null,
  handleChangeStatusConfiguration = () => null,
}) => {
  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <React.Fragment>
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer"
              onClick={() => handleCloseDialog(false)}
            />
          </div>
        </div>
        <div>
          <p className="text-headline4 text-center text-neutral-1">
            {statusValue ? 'Inactivate Configuration' : 'Activate Configuration'}
          </p>
          <p className="text-body1 text-neutral-3 text-center py-6">
            {statusValue
              ? 'Do you really want to inactivate this configuration?'
              : 'Do you really want to activate this configuration?'}
          </p>
          <div className="flex justify-between gap-3">
            <ButtonBase
              type="button"
              className="w-[180px] h-[52px] border-0 bg-primary-1 text-hairline1 min-w-min text-center text-neutral-8"
              onClick={() => handleCloseDialog(false)}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              type="reset"
              className="w-[180px] h-[52px] text-hairline1 bg-neutral-8 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4"
              onClick={handleChangeStatusConfiguration}
            >
              {statusValue ? 'Inactivate' : 'Activate'}
            </ButtonBase>
          </div>
        </div>
      </React.Fragment>
    </BaseDialog>
  )
}

export default ChangeStatusDialog
