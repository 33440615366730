import React, { useEffect, useRef, useState } from 'react'
import ButtonBase from '../../../../components/partials/button'
import ReactLoading from 'react-loading'
import { ActionItem, HoverSubjectItem, VariablesSubjectList } from './interface'
import IconDot from '../../../../assets/images/icon-dot.svg'
import { MESSAGES, STATUS } from '../../../../constants/messages'
import { useSession } from '../../../../helpers/checkSession'
import SignleRowActionBase from '../../../../components/partials/singleRowActionBase'
import { SubjectEmailInfo } from '../../../admin-portal/components/models'
import {
  DELETE_EMAIL_SUBJECT_SETTING,
  GET_ALL_EMAIL_SUBJECT_DATA,
} from '../../../../queries/adminPortal'
import { useMutation, useQuery } from '@apollo/client'
import DialogBasic from '../../../developer-portal/dialog/dialogBasic'
import { notify } from '../../../../components/toastify'
import { SubjectActionType } from '../../../../constants/common'
import SubjectItemDialog from './subjectItem'
const SubjectManagement = () => {
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
  const [isOpenModalDeleteSubject, setIsOpenModalDeleteSubject] = useState<boolean>(false)
  const [isOpenModalEditSubject, setIsOpenModalSubject] = useState<boolean>(false)
  const [isEditSubject, setIsEditSubject] = useState<boolean>(false)
  const [listSubjects, setListSubjects] = useState<SubjectEmailInfo[]>([])

  const initialHoverState: HoverSubjectItem = { id: null, status: false }
  const [hover, setHover] = useState<HoverSubjectItem>(initialHoverState)
  const menuRef = useRef<HTMLDivElement>(null)
  const { sessionChecking, handleErrorSession } = useSession()
  const [showAction, setShowAction] = useState<ActionItem>({
    id: null,
    status: false,
    lastItem: null,
  })
  const defaultSujectEmail = {
    id: '0',
    createdAt: null,
    tDayConfig: {
      configId: '',
      key: '',
      value: '',
    },
    startSubject: '',
    cancelSubject: '',
    textTemplateURL: '',
    action: '',
    status: false,
    emailTemplateURL: '',
  }
  const [currentValueSubject, setCurrentValueSubject] =
    useState<SubjectEmailInfo>(defaultSujectEmail)
  const [deleteEmailSubjectSetting] = useMutation(DELETE_EMAIL_SUBJECT_SETTING)
  const { data: getEmailSubjectSettingData, refetch: getDataSubjectRefetch } = useQuery(
    GET_ALL_EMAIL_SUBJECT_DATA,
    {
      fetchPolicy: 'no-cache',
      variables: {
        limit: 200,
        page: 1,
      },
    } as VariablesSubjectList,
  )

  useEffect(() => {
    if (
      getEmailSubjectSettingData &&
      getEmailSubjectSettingData.getAllEmailManagementConfig?.items.length > 0
    ) {
      setListSubjects(
        getEmailSubjectSettingData.getAllEmailManagementConfig.items.map((item: any) => {
          const firstStepQuery = item.subEmailManagementConfig.filter(
            (subjectItem: any) => subjectItem.step === 1,
          )
          const firstStep = firstStepQuery && firstStepQuery.length > 0 ? firstStepQuery[0] : {}
          return {
            id: item.emailManagementConfigId,
            startSubject: item.startSubject,
            cancelSubject: item.cancelSubject,
            createdAt: item.createdAt,
            emailTemplateURL: firstStep.emailTemplateURL,
            numberOfDayApply: firstStep.numberOfDayApply,
            action: firstStep.action,
            textTemplateURL: firstStep.textTemplateURL,
            subEmailManagementConfig: item.subEmailManagementConfig?.map((subItem: any) => subItem),
            status: true,
          }
        }),
      )
    } else {
      setListSubjects([])
    }
  }, [getEmailSubjectSettingData])

  const onHandleClickAction = (item: any) => {
    if (showAction.lastItem == item.id) {
      return
    }
    setShowAction({
      id: item.id,
      status: true,
      lastItem: item.id,
    })
    setCurrentValueSubject({
      ...item,
    })
  }

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setShowAction({ lastItem: showAction.id, id: null, status: false })
      }
    }
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showAction])

  const onHandleEditSubjectItem = () => {
    setIsEditSubject(true)
    setIsOpenModalSubject(true)
  }

  const onHandleAddSubjectItem = () => {
    setIsEditSubject(false)
    setIsOpenModalSubject(true)
  }

  const handleCloseEditInfoDialog = () => {
    setIsOpenModalSubject(false)
    setIsEditSubject(false)
    setCurrentValueSubject(defaultSujectEmail)
  }

  const onHandleToggleDeleteSubject = async () => {
    setIsOpenModalDeleteSubject(true)
  }

  const handleRefetchData = () => {
    getDataSubjectRefetch()
  }

  const handleCloseDeleteSubjectDialog = () => {
    setIsOpenModalDeleteSubject(false)
  }
  const onHandleDeleteSubject = async () => {
    if ((await sessionChecking()) === false) {
      try {
        await deleteEmailSubjectSetting({
          variables: {
            emailManagementConfigId: currentValueSubject.id,
          },
        })
        setIsOpenModalDeleteSubject(false)
        getDataSubjectRefetch()
        notify(MESSAGES.SUCCESS.S_SUBJECT_UPDATE_SETTING, STATUS.SUCCESS)
      } catch (error) {
        handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
        setIsOpenModalDeleteSubject(false)
      }
    }
  }
  return (
    <div className="mt-4">
      <div className="flex items-center justify-between pb-5">
        <p className="text-headline5A text-neutral-1 md:ml-[-40px]">Subject management</p>
        <ButtonBase
          className="w-[200px] md:mr-[-40px]"
          bgHover="bg-violet-900"
          onClick={onHandleAddSubjectItem}
          bgDisabled="bg-violet-600"
        >
          Add new
        </ButtonBase>
      </div>
      <div className="md:mr-[-40px] md:ml-[-40px]">
        <div className="w-full top-[88px] border-2 border-neutral-6 bg-neutral-8 rounded-2xl">
          <ul className="flex bg-primary-shade4 px-6 py-5 justify-start rounded-t-[14px]">
            <li className="w-[210px] min-w-[64px]">
              <span className="text-base font-semibold font-montserrat text-neutral-2">
                Start Subject
              </span>
            </li>
            <li className="w-[210px] min-w-[100px] ml-10">
              <span className="text-base font-semibold font-montserrat text-neutral-2">
                Cancel Subject
              </span>
            </li>
            <li className="w-[120px] min-w-[100px]">
              <span className="text-base font-semibold font-montserrat text-neutral-2">
                Days delay
              </span>
            </li>
            <li className="w-[220px] min-w-[64px] md:mr-4">
              <span className="text-base font-semibold font-montserrat text-neutral-2">
                Email Template
              </span>
            </li>
            <li className="w-[200px] min-w-[64px] md:mr-4">
              <span className="text-base font-semibold font-montserrat text-neutral-2">
                Text Template
              </span>
            </li>
            <li className="w-[200px] min-w-[64px] md:mr-4">
              <span className="text-base font-semibold font-montserrat text-neutral-2">Action</span>
            </li>
            <li className="min-w-[24px]"></li>
          </ul>
          {isLoadingTable ? (
            <div className="flex justify-center p-5">
              <ReactLoading type="spin" color="#A989D8" height={30} width={30} />
            </div>
          ) : listSubjects !== null && listSubjects.length > 0 ? (
            <React.Fragment>
              <div className="mt-5 ">
                {listSubjects?.map((item) => (
                  <ul
                    className="flex justify-start h-auto px-6 py-5 mb-3 font-montserrat text-body2 text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto"
                    key={item.id}
                    onMouseEnter={() => {
                      setHover({
                        id: item.id,
                        status: true,
                      })
                    }}
                    onMouseLeave={() => {
                      setHover({
                        id: null,
                        status: false,
                      })
                    }}
                  >
                    <li className="w-[210px] min-w-[64px] min-h-5">
                      <p className="break-world">{item.startSubject}</p>
                    </li>
                    <li className="w-[210px] min-w-[140px]">
                      <p className="ml-10 break-world ">{item.cancelSubject}</p>
                    </li>
                    <div className="relative break-all w-[120px] min-w-[100px] mr-10 h-8">
                      {hover?.status && item.id === hover.id ? (
                        <div className="w-full break-all">
                          <li className="overflow-hidden text-ellipsis whitespace-nowrap min-w-[80px] ml-10">
                            {item.tDayConfig?.value}
                          </li>
                        </div>
                      ) : (
                        <li className="overflow-hidden text-ellipsis whitespace-nowrap ml-10 min-w-[140px]">
                          {item.tDayConfig?.value}
                        </li>
                      )}
                    </div>
                    <li className="w-[220px] hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto">
                      <a
                        id="wfu"
                        style={{ color: 'green', textDecoration: 'none' }}
                        onClick={() => window.open(`${item.emailTemplateURL}`, '_blank')}
                      >
                        {' '}
                        email template{' '}
                      </a>
                    </li>
                    <li className="w-[200px] hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto">
                      <a
                        id="wfu"
                        style={{ color: 'green', textDecoration: 'none' }}
                        onClick={() => window.open(`${item.textTemplateURL}`, '_blank')}
                      >
                        {' '}
                        text template{' '}
                      </a>
                    </li>
                    <li className="w-[200px] hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto">
                      {SubjectActionType.filter((action: any) => action.id === item.action) &&
                      SubjectActionType.filter((action: any) => action.id === item.action).length >
                        0 ? (
                        <p className="break-world">
                          {
                            SubjectActionType.filter((action: any) => action.id === item.action)[0]
                              .name
                          }
                        </p>
                      ) : (
                        <p className="break-world">{SubjectActionType[0].name}</p>
                      )}
                    </li>
                    <button
                      className="min-w-[24px]  mb-auto"
                      onClick={() => onHandleClickAction(item)}
                    >
                      <div className="relative ">
                        <img src={IconDot} alt="Icon-Dot" className="" />
                        {showAction?.id === item.id && showAction.status && (
                          <div
                            className={`absolute right-[2px] h-auto p-3 border shadow-2xl w-60 border-neutral-7 bg-neutral-8 rounded-xl 'z-10'`}
                            ref={menuRef}
                          >
                            <SignleRowActionBase
                              handleAction={onHandleEditSubjectItem}
                              action="edit"
                              txt="Edit"
                            />
                            <hr className="my-1 border-t-neutral-7" />
                            <SignleRowActionBase
                              handleAction={onHandleToggleDeleteSubject}
                              action="delete"
                              txt="Delete"
                            />
                          </div>
                        )}
                      </div>
                    </button>
                  </ul>
                ))}
              </div>
              {isOpenModalDeleteSubject && (
                <DialogBasic
                  modalIsOpen={isOpenModalDeleteSubject}
                  title="Delete Subject Setting"
                  confirmation="Are you sure you want to permanently delete this setting"
                  action="Delete"
                  handleCloseDialog={handleCloseDeleteSubjectDialog}
                  handleAction={onHandleDeleteSubject}
                />
              )}
              {isOpenModalEditSubject && (
                <SubjectItemDialog
                  modalIsOpen={isOpenModalEditSubject}
                  subjectInfo={currentValueSubject}
                  isEditSubject={isEditSubject}
                  handleCloseDialog={handleCloseEditInfoDialog}
                  handleRefetchData={handleRefetchData}
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isOpenModalEditSubject && (
                <SubjectItemDialog
                  modalIsOpen={isOpenModalEditSubject}
                  subjectInfo={currentValueSubject}
                  isEditSubject={isEditSubject}
                  handleCloseDialog={handleCloseEditInfoDialog}
                  handleRefetchData={handleRefetchData}
                />
              )}
              <p className="pt-5 pb-3 text-center text-body1 text-neutral-4">No data available.</p>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}
export default SubjectManagement
