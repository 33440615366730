/* eslint-disable react/jsx-key */
import { useQuery } from '@apollo/client'
import { isArray, isBoolean } from 'lodash'
import React, { useEffect, useState } from 'react'
import BaseDialog from '../../../components/dialog'
import ButtonBase from '../../../components/partials/button'
import { isValidHttpUrl } from '../../../helpers/functionUtils'
import { GET_JOB_DATA_AND_HISTORY_JOB } from '../../../queries/adminPortal'
import Moment from 'react-moment'

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleCloseDialog?: (val: boolean) => void
  jobData: any
}

interface JobInformation {
  label: string
  value: any
}

const styleDetailJobItem = {
  content: {
    width: '90%',
    maxWidth: '800px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
  },
}

const camelCaseToWords = (s: string): string => {
  if (s === 'textTemplateURL') {
    return 'Text Template URL'
  } else if (s === 'emailTemplateURL') {
    return 'Email Template URL'
  }
  const result = s.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

const DetailItemDialog: React.FunctionComponent<PropsAction> = ({
  jobData,
  modalIsOpen,
  handleCloseDialog = () => null,
}) => {
  const [itemHistory, setItemHistory] = useState<any[]>([])
  const [listJobInformation, setListJobInformation] = useState<JobInformation[]>([])
  const { data: getJobDataAndHistory } = useQuery(GET_JOB_DATA_AND_HISTORY_JOB, {
    fetchPolicy: 'no-cache',
    variables: {
      jobId: jobData?.jobId,
    },
    skip: !jobData,
  })
  const classInfoKey = 'text-neutral-3 text-body2 font-semibold leading-6 min-w-[160px]'
  const classInfoVal = 'text-neutral-3 text-body1 min-w-[460px] break-words'

  useEffect(() => {
    if (getJobDataAndHistory) {
      const dataDetail = getJobDataAndHistory.getDetailJobById.item
      const dataHistory = getJobDataAndHistory.getDetailJobById.historyItems
      const inform: JobInformation[] = []
      for (const [key, value] of Object.entries(dataDetail)) {
        if (!value) continue
        if (key === 'gaJobLog' && isArray(value) && value.length > 0) {
          // const label = value[0].type === 'ERROR' ? 'Error Message' : 'Message'
          inform.push({ label: 'Message', value: value[value.length - 1].message })
        } else {
          inform.push({ label: camelCaseToWords(key), value })
        }
      }
      setItemHistory(dataHistory)
      setListJobInformation(inform)
    }
  }, [getJobDataAndHistory])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={styleDetailJobItem}>
      <div className="w-[758px] p-1 pr-0">
        <p className="mb-2 text-headline4">Detail Job Information</p>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        <div className="flex-row h-[300px] overflow-y-scroll">
          {listJobInformation.map((item) => (
            <div className="flex mb-4">
              <div className="w-1/4">
                <p className={classInfoKey}>{item.label}</p>
                {/* <p>{item.label}</p> */}
              </div>
              <div className="w-3/4">
                {isBoolean(item.value) ? (
                  <p className={classInfoVal}>{item.value.toString()}</p>
                ) : isValidHttpUrl(item.value) ? (
                  <a href={item.value} className={classInfoVal}>
                    {item.value}
                  </a>
                ) : (
                  <p className={classInfoVal}>{item.value}</p>
                )}
              </div>
            </div>
          ))}
        </div>
        <p className="mb-2 mt-3 text-headline4">Job History</p>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        {itemHistory.length < 1 ? (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        ) : (
          <div className="h-[100px] overflow-y-scroll">
            <table className="w-full overflow-scroll text-left">
              <thead>
                <tr>
                  <th className="px-4 whitespace-nowrap">Job Id</th>
                  <th className="px-4 whitespace-nowrap">Step</th>
                  <th className="px-4 whitespace-nowrap">Action</th>
                  <th className="px-4 whitespace-nowrap">Status</th>
                  <th className="px-4 whitespace-nowrap">Stage Date</th>
                  <th className="px-4 whitespace-nowrap">Email Template URL</th>
                  <th className="px-4 whitespace-nowrap">Text Template URL</th>
                </tr>
              </thead>
              <tbody>
                {itemHistory.map((job) => (
                  <tr>
                    <td className="px-4">{job.jobId}</td>
                    <td className="px-4">{job.step}</td>
                    <td className="px-4">{job.action}</td>
                    <td className="px-4">{job.status}</td>
                    {/* <td className="px-4">{job.stageDate}</td> */}
                    <td className="px-4">
                      {job.stageDate && <Moment format="MM/DD/YYYY">{job.stageDate}</Moment>}
                    </td>
                    <td className="px-4 w-full max-w-40">{job.emailTemplateURL}</td>
                    <td className="px-4 w-full max-w-40">{job.textTemplateURL}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <hr className="mt-4 mb-2" />
        <ButtonBase
          type="submit"
          className="w-[150px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
          onClick={() => {
            handleCloseDialog(false)
          }}
          bgDisabled="bg-primary-shade3"
        >
          Close
        </ButtonBase>
      </div>
    </BaseDialog>
  )
}

export default DetailItemDialog
