import React, { useState } from 'react'
import Tabs, { TabsType } from '../../components/partials/tabs'
import SystemConfiguration from '../system-configuration'
import SystemMonitoring from '../system-monitoring'
import UsersManagement from '../users-management'
import PrincipalAccountManagement from '../principal-account-management'
import GoogleAnalyticManagement from '../google-analytics-management'

// Tabs Array
const tabs: TabsType = [
  {
    label: 'System monitoring',
    index: 1,
    Component: SystemMonitoring,
  },
  {
    label: 'Users management',
    index: 2,
    Component: UsersManagement,
  },
  {
    label: 'System configuration',
    index: 3,
    Component: SystemConfiguration,
  },
  {
    label: 'Principal Account management',
    index: 4,
    Component: PrincipalAccountManagement,
  },
  {
    label: 'Google Analytics management',
    index: 5,
    Component: GoogleAnalyticManagement,
  },
]

const AdminPortal = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)

  return (
    <div>
      <Tabs
        needResponsive={true}
        selectedTab={selectedTab}
        pageName="Admin portal"
        onClick={setSelectedTab}
        tabs={tabs}
      />
    </div>
  )
}

export default AdminPortal
