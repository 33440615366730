import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import iconPlus from '../../../../assets/images/icon-plus.svg'
import iconTimes from '../../../../assets/images/icon-times.svg'
import iconTrash from '../../../../assets/images/icon-trash-gray.svg'
import BaseDialog from '../../../../components/dialog'
import ButtonBase from '../../../../components/partials/button'
import DropdownBase, { Option } from '../../../../components/partials/dropDown'
import { notify } from '../../../../components/toastify'
import { SubjectActionType } from '../../../../constants/common'
import { MESSAGES, STATUS } from '../../../../constants/messages'
import { useSession } from '../../../../helpers/checkSession'
import {
  ADD_EMAIL_SUBJECT_SETTING,
  EDIT_EMAIL_SUBJECT_SETTING,
  GET_CONFIGURATION_BY_TYPE,
} from '../../../../queries/adminPortal'
import { SubjectEmailInfo } from '../../../admin-portal/components/models'
import { Configuration } from './interface'

export type SubjectInfoFormFields = {
  startSubject: string
  cancelSubject: string
  emailTemplateURL: string
  textTemplateURL: string
  numberOfDayApply: number
  tDayId: string
  step: number
}

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleEdit?: () => void
  handleCloseDialog?: (val: boolean) => void
  isEditSubject?: boolean
  subjectInfo?: SubjectEmailInfo | any
  handleRefetchData?: () => void
}
const SubjectItemDialog: React.FunctionComponent<PropsAction> = ({
  modalIsOpen,
  handleCloseDialog = () => null,
  subjectInfo,
  isEditSubject,
  handleRefetchData = () => null,
}) => {
  const { register, control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      headerConfig: [] as any,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headerConfig',
  })

  const actionList = SubjectActionType
  // const tDayList = ConfigurationDateSettingKeyName
  const [tDayList, setTDayList] = useState<Configuration[]>([])
  const [isSubjectScroll, setIsSubjectItemScroll] = useState<boolean>(false)
  const [tDaySelected, setTDaySelected] = useState<Configuration[]>([])

  const handleChangeActionLevel = (val: any) => {
    const currentIdx = val.key - 1
    setValue(`headerConfig.${currentIdx}.action`, val.id)
  }

  const handleChangeTDay = (val: any) => {
    setValue(`headerConfig.${val.idx}.tDayKey`, val.key)
    setValue(`headerConfig.${val.idx}.tDayValue`, val.value)
    setValue(`headerConfig.${val.idx}.tDayId`, val.id)
    setValue(`headerConfig.${val.idx}.tDayList`, tDayList)

    const temp = [...tDaySelected]
    temp[val.idx] = val

    setTDaySelected(temp)
  }

  const { handleErrorSession } = useSession()
  const [editEmailSubjectSetting] = useMutation(EDIT_EMAIL_SUBJECT_SETTING)
  const [addEmailSubjectSetting] = useMutation(ADD_EMAIL_SUBJECT_SETTING)
  const { data: configurationByTypeData, refetch: getConfigurationByType } = useQuery(
    GET_CONFIGURATION_BY_TYPE,
    {
      fetchPolicy: 'no-cache',
      variables: {
        type: 'DATE_SETTING',
        direction: 'ASC',
      },
    },
  )
  const onSubmit = handleSubmit(async (dataSubmit) => {
    try {
      if (isEditSubject) {
        const subjectVariables = {
          data: {
            startSubject: dataSubmit.headerConfig[0].startSubject.trim(),
            cancelSubject: dataSubmit.headerConfig[0].cancelSubject.trim(),
            subEmailManagementConfig: dataSubmit.headerConfig.map((item: any, idx: number) => {
              return {
                emailTemplateURL: item?.emailTemplateURL.trim(),
                textTemplateURL: item?.textTemplateURL.trim(),
                step: idx + 1,
                action: item.action,
                tDayConfigId: item.tDayId,
              }
            }),
          },
          emailManagementConfigId: subjectInfo.id,
        }
        await editEmailSubjectSetting({ variables: subjectVariables })
      } else {
        const subjectVariables = {
          data: {
            startSubject: dataSubmit.headerConfig[0].startSubject.trim(),
            cancelSubject: dataSubmit.headerConfig[0].cancelSubject.trim(),
            subEmailManagementConfig: dataSubmit.headerConfig.map((item: any, idx: number) => {
              return {
                emailTemplateURL: item?.emailTemplateURL.trim(),
                textTemplateURL: item?.textTemplateURL.trim(),
                step: idx + 1,
                action: item.action,
                tDayConfigId: item.tDayId,
              }
            }),
          },
        }
        await addEmailSubjectSetting({ variables: subjectVariables })
      }
      handleRefetchData()
      notify(MESSAGES.SUCCESS.S_SUBJECT_UPDATE_SETTING, STATUS.SUCCESS)
      handleCloseDialog(false)
      reset()
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_UPDATE_CARRIER_INFO)
    }
  })

  const handleSaveChanges = async () => {
    await onSubmit()
  }

  const handleCloseEditSubjectDialog = () => {
    handleCloseDialog(false)
    setTDaySelected([])
    reset()
  }
  const addNewSubjectItem = (lastItem: any, idx: number) => {
    if (!getValues(`headerConfig.${idx}.tDayValue`)) {
      notify(MESSAGES.WARNING.W_MISSING_INFORMATION_ADD_SUBJECT, STATUS.WARNING)
      return
    }

    const result = tDayList.filter(
      (tDay) => parseInt(tDay.value, 10) > parseInt(tDaySelected[idx].value, 10),
    )

    if (result.length === 0) {
      notify(MESSAGES.WARNING.W_OUT_OF_DATE_CONFIG, STATUS.WARNING)
      return
    }

    append({
      startSubject: getValues(`headerConfig.${idx}.startSubject`),
      cancelSubject: getValues(`headerConfig.${idx}.cancelSubject`),
      emailTemplateURL: '',
      textTemplateURL: '',
      tDayId: '',
      tDayValue: '',
      tDayKey: '',
      action: actionList[0].id,
    })
  }

  const removeSubjectItem = (idx: number) => {
    const temp = [...tDaySelected]
    temp.splice(idx, 1)
    setTDaySelected(temp)
    remove(idx)
  }

  const actionListIdx = (val: Option[], idx: number) => {
    return val.map((valItem: Option) => {
      valItem.key = (idx + 1).toString()
      return valItem
    })
  }

  const handleTDayOption = (idx: number) => {
    if (idx === 0) return tDayList
    const result = tDayList.filter(
      (tDay) => parseInt(tDay.value, 10) > parseInt(tDaySelected[idx - 1].value, 10),
    )
    return result
  }

  useEffect(() => {
    if (isEditSubject && subjectInfo) {
      const temp: Configuration[] = []
      reset({
        headerConfig: subjectInfo.subEmailManagementConfig?.map((item: any) => {
          temp.push(item.tDayConfig)
          return {
            startSubject: subjectInfo.startSubject,
            cancelSubject: subjectInfo.cancelSubject,
            emailTemplateURL: item.emailTemplateURL,
            textTemplateURL: item.textTemplateURL,
            action: item.action,
            step: item.step,
            tDayId: item.tDayConfig.configId,
            tDayKey: item.tDayConfig.key,
            tDayValue: item.tDayConfig.value,
            tDayList: tDayList,
          }
        }),
      })

      setTDaySelected(temp)
    } else {

      reset({
        headerConfig: [
          {
            startSubject: '',
            cancelSubject: '',
            emailTemplateURL: '',
            textTemplateURL: '',
            numberOfDayApply: 0,
            action: actionList[0]?.id,
            tDayKey: '',
            tDayValue: '',
            tDayId: '',
            step: 1,
          },
        ],
      })
    }
  }, [subjectInfo, isEditSubject])

  useEffect(() => {
    if (configurationByTypeData && configurationByTypeData.getConfigurationByType.length > 0) {
      setTDayList(configurationByTypeData.getConfigurationByType)
    } else {
      setTDayList([])
    }
  }, [configurationByTypeData])

  const styleSubjectItems = {
    content: {
      width: '90%',
      maxWidth: '950px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: '16px',
      boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
      background: '#fdfdfd',
    },
  }

  useEffect(() => {
    const clientHeight = document.querySelector('#cus-scrollbar')?.clientHeight
    if (document.querySelector('#cus-scrollbar')?.clientHeight && clientHeight) {
      setIsSubjectItemScroll(fields.length >= 2)
    }
  }, [document.querySelector('#cus-scrollbar')?.clientHeight, fields])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={styleSubjectItems}>
      <div className="w-[900px] p-1 pr-0 bg-red">
        <div className="float-right">
          <img
            src={iconTimes}
            alt=""
            className="cursor-pointer"
            onClick={handleCloseEditSubjectDialog}
          />
        </div>
        <p className="mb-2 text-headline4">Subject management</p>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        {isEditSubject ? (
          <p className="mb-3 text-headline5A">Edit subject</p>
        ) : (
          <p className="mb-3 text-headline5A">Add subject</p>
        )}
        <div
          id="cus-scrollbar"
          className={`cus-scrollbar overflow-y-auto mt-5 mb-3 ${
            isSubjectScroll ? 'mr-2 overflow-y-auto' : 'mr-0 overflow-y-visible'
          }`}
        >
          <form
            className={`${isSubjectScroll ? 'pr-2' : 'pr-0'} max-h-[588px]`}
            onSubmit={handleSaveChanges}
          >
            {fields.map((item, idx) => {
              return (
                <div key={idx}>
                  {idx >= 1 && (
                    <img
                      src={iconTrash}
                      alt="iconTrash"
                      className="pr-3 cursor-pointer"
                      onClick={() => removeSubjectItem(idx)}
                    />
                  )}
                  {idx === 0 && (
                    <div className="grid grid-cols-2 gap-2">
                      <div className="mt-2">
                        <p className="mb-2 text-hairline2">Start subject</p>
                        <input
                          id={`startSubject${idx}`}
                          type="text"
                          placeholder={'Enter start subject'}
                          className="w-[356px] mt-1 text-body1 text-neutral-3 mr-5 focus:border-current focus:ring-0 relative inline-flex appearance-none border-2 font-bold placeholder-neutral-5 placeholder:font-medium focus:placeholder-neutral-5 transition-colors ease-in-out rounded-xl h-[52px] px-4 leading-tight focus:outline-none focus:ring-neutral-5 focus:shadow-outline"
                          {...register(`headerConfig.${idx}.startSubject`)}
                        />
                      </div>
                      <div className="mt-2 ml-2">
                        <p className="mb-2 text-hairline2">Cancel subject</p>
                        <input
                          id={`cancelSubject${idx}`}
                          type="text"
                          placeholder={'Enter cancel subject'}
                          className="w-[356px] mt-1 text-body1 text-neutral-3 mr-5 focus:border-current focus:ring-0 relative inline-flex appearance-none border-2 font-bold placeholder-neutral-5 placeholder:font-medium focus:placeholder-neutral-5 transition-colors ease-in-out rounded-xl h-[52px] px-4 leading-tight focus:outline-none focus:ring-neutral-5 focus:shadow-outline"
                          {...register(`headerConfig.${idx}.cancelSubject`)}
                        />
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-2">
                    <div className="mt-2">
                      <p className="mb-2 text-hairline2">Delay days</p>
                      <div className="w-[356px] mt-1 ">
                        <DropdownBase
                          options={handleTDayOption(idx).map((tDayItem) => ({
                            idx,
                            id: tDayItem.configId,
                            name: tDayItem.key,
                            value: tDayItem.value,
                            description: tDayItem.value,
                          }))}
                          handleOptions={handleChangeTDay}
                          placeHolder="Choose t-day"
                          isError={false}
                          initialValue={getValues(`headerConfig.${idx}.tDayKey`)}
                          hasDescription={true}
                          initialDescription={getValues(`headerConfig.${idx}.tDayValue`)}
                        />
                      </div>
                    </div>
                    <div className="mt-2 ml-2">
                      <p className="mb-2 text-hairline2">Action</p>
                      <div className="w-[356px] mt-1 ">
                        <DropdownBase
                          options={actionListIdx(actionList, idx)}
                          handleOptions={handleChangeActionLevel}
                          placeHolder="Choose action type"
                          isError={false}
                          initialValue={
                            actionList.filter((actionItem: Option) => {
                              return actionItem.id === getValues(`headerConfig.${idx}.action`)
                            })[0].name ?? actionList[0].name
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="mt-4">
                      <p className="mb-2 text-hairline2">Email template link</p>
                      <input
                        id={`emailTemplateURL${idx}`}
                        type="text"
                        placeholder={'Enter template link'}
                        className="w-[356px] mt-1 text-body1 text-neutral-3 mr-5 focus:border-current focus:ring-0 relative inline-flex appearance-none border-2 font-bold placeholder-neutral-5 placeholder:font-medium focus:placeholder-neutral-5 transition-colors ease-in-out rounded-xl h-[52px] px-4 leading-tight focus:outline-none focus:ring-neutral-5 focus:shadow-outline"
                        {...register(`headerConfig.${idx}.emailTemplateURL`)}
                      />
                    </div>
                    <div className="mt-4 ml-2">
                      <p className="mb-2 text-hairline2">Text template link</p>
                      <input
                        id={`textTemplateURL${idx}`}
                        type="text"
                        placeholder={'Enter template link'}
                        className="w-[356px] mt-1 text-body1 text-neutral-3 mr-5 focus:border-current focus:ring-0 relative inline-flex appearance-none border-2 font-bold placeholder-neutral-5 placeholder:font-medium focus:placeholder-neutral-5 transition-colors ease-in-out rounded-xl h-[52px] px-4 leading-tight focus:outline-none focus:ring-neutral-5 focus:shadow-outline"
                        {...register(`headerConfig.${idx}.textTemplateURL`)}
                      />
                    </div>
                  </div>

                  <hr
                    className="mt-4 mb-2"
                    style={{ border: '1px solid black', borderRadius: '5px!important' }}
                  />
                  {idx === fields.length - 1 && (
                    <ButtonBase
                      type="reset"
                      className={` mt-2 flex mx-5 justify-start bg-neutral-8 p-0 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4`}
                      onClick={(e) => {
                        e.preventDefault()
                        addNewSubjectItem(item, idx)
                      }}
                    >
                      <img src={iconPlus} alt="iconPlus" className="pl-3 pr-3" />
                    </ButtonBase>
                  )}
                </div>
              )
            })}
          </form>
        </div>
        <div className="flex w-[750px]">
          <ButtonBase
            type="submit"
            className="w-[374px] my-4 bg-primary-1 min-w-min text-center"
            onClick={handleSaveChanges}
            bgDisabled="bg-primary-shade3"
          >
            Save change
          </ButtonBase>
          <ButtonBase
            type="submit"
            className="w-[374px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
            onClick={handleCloseEditSubjectDialog}
            bgDisabled="bg-primary-shade3"
          >
            Cancel
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default SubjectItemDialog
