export const tableHeaderGAList = [
  {
    id: 1,
    minW: 'min-w-[72px]',
    minWRow: 'w-[60px] min-w-[64px]',
    maxWidth: '72px',
    label: 'Job Id',
  },
  {
    id: 2,
    minW: 'w-[158px] min-w-[92px]',
    minWRow: 'w-[150px] min-w-[92px] break-words',
    label: 'Job Name',
    maxWidth: '150px',
    isFilter: true,
  },
  {
    id: 3,
    minW: 'w-[130px] min-w-[92px]',
    minWRow: 'w-[110px] min-w-[92px] break-all',
    maxWidth: '130px',
    label: 'Stage Date',
  },
  {
    id: 4,
    minW: 'w-[125px] min-w-[92px]',
    minWRow: 'w-[100px] min-w-[92px] break-all ml-3',
    maxWidth: '125px',
    label: 'Stage Completed',
  },
  {
    id: 5,
    minW: 'w-[150px] min-w-[68px]',
    maxWidth: '148px',
    minWRow: 'w-[148px] min-w-[68px] break-words',
    label: 'Status',
  },
  {
    id: 6,
    minW: 'w-[150px] min-w-[68px]',
    minWRow: 'w-[130px] min-w-[68px] break-words ml-3',
    label: 'Application Number',
    maxWidth: '150px',
  },
  {
    id: 7,
    minW: 'w-[200px] min-w-[100px]',
    minWRow: 'w-[200px] min-w-[100px] break-all',
    label: 'Email address',
    maxWidth: '200px',
  },
  {
    id: 8,
    minW: 'w-[150px] min-w-[82px]',
    minWRow: 'w-[150px] min-w-[82px] break-all',
    label: 'Text No',
    maxWidth: '150px',
  },
]
